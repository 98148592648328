import React from 'react'
import AppRoutes from './routes.jsx'

const App = () => (
		<div className='App'>
			<AppRoutes />
		</div>
	)

export default App
