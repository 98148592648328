import React from 'react'

const fontSizeClasses = [
	'text-xs',
	'text-sm',
	'text-md',
	'text-lg',
	'text-xl',
	'text-2xl',
	'text-3xl',
	'text-4xl',
	'text-5xl',
]

const Subtitle = ({ text, level, borderColor = 'white', className, ...props }) => {
	const fontSizeMultiplier = Math.max(0, 8 - level)
	const fontSize = fontSizeClasses[fontSizeMultiplier] || fontSizeClasses[0]
	const isUppercase = level === 1 ? 'uppercase' : ''
	const Tag = `h${level}`
	const classNames = `${isUppercase} border-${borderColor} mb-4 ${fontSize} font-serif ${className || ''}`.trim()

	return React.createElement(Tag, { className: classNames, ...props }, text)
}

export default Subtitle