import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'

const BackgroundImage = () => {
  return (
    <img
      className="object-cover h-full w-full absolute"
      src="/images/photos/art.webp"
      alt="Background"
    />
  )
}

const Logo = () => {
  return (
    <img
      src="/images/graphics/logos/amiciart.webp"
      alt="Amicitia Chorus Logo"
      width="0"
      height="0"
      sizes="100vw"
      className="w-full h-auto"
    />
  )
}

const NavLinks = () => {
  return (
    <div style={{ transition: 'all 0.25s linear' }}>
      <ul className="w-full text-white text-center uppercase font-bold absolute bottom-24 left-1/2 transform -translate-x-1/2 md:text-lg flex flex-col">
        <Link
          className="hover:text-amicitia transition duration-200 ease-in-out"
          to="/amicitia/chorus"
        >
          Amicitia Chorus
        </Link>
        <Link
          className="hover:text-amicitia transition duration-200 ease-in-out"
          to="/amicitia/crescendo"
        >
          Amicitia Crescendo
        </Link>
      </ul>
    </div>
  )
}

export default function App() {
  return (
    <div>
      <Helmet>
        {/* Preload images */}
        <link rel="preload" as="image" href="/images/photos/art.webp" />
        <link
          rel="preload"
          as="image"
          href="/images/graphics/logos/amiciart.webp"
        />

        {/* Basic Meta Tags */}
        <title>Amiciart</title>
        <meta
          name="description"
          content="Amiciart. Unidos pela amizade e pela música."
        />
      </Helmet>

      <main className="flex min-h-screen flex-col items-center justify-between bg-green-500">
        <div className="relative h-screen w-screen">
          <BackgroundImage />
          <div className="w-full h-full">
            <div className="w-60 flex items-center justify-center m-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:w-96">
              <Logo />
            </div>
            <NavLinks />
          </div>
        </div>
      </main>
      <Footer socials={[]} />
    </div>
  )
}
