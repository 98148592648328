import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home.jsx'
import Amicitia from './pages/Amicitia.jsx'
import Crescendo from './pages/Crescendo.jsx'

export default function AppRoutes() {
	return (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/amicitia/chorus' element={<Amicitia />} />
			<Route path='/amicitia/crescendo' element={<Crescendo />} />
		</Routes>
	)
}
