import { Transition } from '@headlessui/react'
import React, { useCallback, useEffect, useState } from 'react'

const carouselImagePaths = [
	'/images/graphics/carousel/1.webp',
	'/images/graphics/carousel/2.webp',
	'/images/graphics/carousel/3.webp',
	'/images/graphics/carousel/4.webp',
	'/images/graphics/carousel/5.webp',
	'/images/graphics/carousel/6.webp',
]

const HeroCarousel = () => {
	const getRandomImageIndex = useCallback(() => {
		return Math.floor(Math.random() * carouselImagePaths.length)
	}, [])
	
	const [activeImageIndex, setActiveImageIndex] = useState(getRandomImageIndex())

	const updateActiveImageIndex = useCallback(() => {
		setActiveImageIndex((prevImageIndex) => {
			let newImageIndex
			do {
				newImageIndex = getRandomImageIndex()
			} while (newImageIndex === prevImageIndex)
			return newImageIndex
		})
	}, [getRandomImageIndex])

	useEffect(() => {
		const interval = setInterval(updateActiveImageIndex, 10000)

		return () => clearInterval(interval)
	}, [updateActiveImageIndex])

	return (
		<div className='w-screen h-screen relative'>
			{renderCarouselImages()}
			{renderOverlayImage()}
			{renderLogoImage()}
		</div>
	)

	function renderCarouselImages() {
		return carouselImagePaths.map((imagePath, index) => (
			<Transition
				key={index}
				show={index === activeImageIndex}
				enter='transition-opacity ease-linear duration-1000'
				enterFrom='opacity-0'
				enterTo='opacity-100'
				leave='transition-opacity ease-linear duration-1000'
				leaveFrom='opacity-100'
				leaveTo='opacity-0'>
				<div className='w-screen h-screen absolute'>
					<img src={imagePath} alt={`${index + 1}`} className='w-full h-full object-cover' />
				</div>
			</Transition>
		))
	}

	function renderOverlayImage() {
		return (
			<img
				src='/images/graphics/font.png'
				alt='Overlay'
				className='hidden md:block w-full h-full absolute object-cover top-0'
			/>
		)
	}

	function renderLogoImage() {
		return (
			<img
				src='/images/graphics/logos/tia-white.png'
				alt='Logo'
				className='w-72 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:hidden'
			/>
		)
	}
}

export default HeroCarousel
