import React from 'react'

const ArrowDown = ({ onClick = () => {}, className = '', text = 'inscrições', color = 'black' }) => {
	const classNames =
		'flex justify-center ' +
		'text-' +
		color +
		' self-center absolute bottom-5 left-0 right-0 mx-auto cursor-pointer animate-bounce z-10' +
		' ' +
		className
	return (
		<div onClick={onClick} className={classNames}>
			{text} <span> ↓ </span>
		</div>
	)
}

export default ArrowDown
